import { IonContent, IonPage, IonButton } from '@ionic/react';
import React, { useState, useEffect, useRef } from 'react';
import { StepProps } from './Step';
import '../../theme/pages/finishworkflow.css';
import { observer } from 'mobx-react';
import Utils from '../../utils/Utils';
import Banner from '../../components/Banner';
import DriverLicenseDataModel from '../../models/DriverLicenseDataModel';
import NativeBarcodeScanner from '../../components/NativeBarcodeScanner';
import '../../theme/pages/scan.css';
import BarcodeScannerOptions from '../../models/BarcodeScannerOptions';
import BarcodeScannerResults from '../../models/BarcodeScannerResults';
import WebBarcodeScanner from '../../components/WebBarcodeScanner';
import { useErrorHandler } from 'react-error-boundary';
import DesignHelper from './DesignHelper';
import { ThemeType } from '../../commons/Constants';
import CameraUtils from '../../utils/CameraUtils';

const Scan = observer((props: StepProps): JSX.Element => {
  const barcodeScanner = useRef<NativeBarcodeScanner>(null);
  const errorHandler = useErrorHandler();

  const [isBarcodeScannerFront, setIsBarcodeScannerFront] = useState(false);
  const [isCameraSettingLoaded, setIsCameraSettingLoaded] = useState(false);

  useEffect(() => {
    const loadCameraSetting = async () => {
      const isFront = await CameraUtils.getCameraFrontSide();
      setIsBarcodeScannerFront(isFront);
      setIsCameraSettingLoaded(true);
    };
    loadCameraSetting();
  }, []);

  useEffect(() => {
    if (isCameraSettingLoaded) {
      barcodeScanner.current?.restart(getBarcodeScannerOptions());
    }
  }, [isBarcodeScannerFront, isCameraSettingLoaded]);

  const goToNextStepSkipped = () => {
    const { workflowStore } = props.appStore;
    workflowStore.isDLScanSkipped = true;
    workflowStore.goToNextStep();
  };

  const getBarcodeScannerOptions = (): BarcodeScannerOptions => ({
    possibleFormats: ['PDF_417'],
    isFrontCamera: isBarcodeScannerFront,
    aspectRatioEqual: true,
  });

  const flipBarcodeScannerCamera = () => {
    setIsBarcodeScannerFront((prevState) => !prevState);
  };

  const onBarcodeScannerSuccess = (res: BarcodeScannerResults) => {
    if (res.barcodeFormat === 'PDF_417') {
      const { workflowStore } = props.appStore;
      const dlData = DriverLicenseDataModel.parseText(res.text);
      workflowStore.setDriverLicenseData(dlData);
      workflowStore.goToNextStep();
    }
  };

  const onBarcodeScannerError = (err: any) => {
    errorHandler(err);
    if (Utils.isNative()) {
      Utils.showErrorToast('An error has occurred');
    }
  };

  const { locationStore, workflowStore } = props.appStore;
  const themeMain = locationStore.themeMain;
  const themeButton = locationStore.themeButton;

  return (
    <IonPage className="scan">
      <IonContent>
        <Banner {...props} />
        {DesignHelper.renderThemeDesignTop(themeMain, locationStore.location?.logo || null)}
        {Utils.isNative() && DesignHelper.renderHomeButton(themeButton, () => workflowStore.reset(true))}

        <div className={themeMain === ThemeType.Old ? 'barcode-scanner-container' : 'barcode-scanner-container-new-design'}>
          {DesignHelper.renderThemeLabelTitle(themeMain, locationStore.tabletTerminologies.dlScanTitle)}
          {Utils.isNative() && Utils.isMobileApp() ? (
            <>
              {isCameraSettingLoaded && (
                <NativeBarcodeScanner
                  ref={barcodeScanner}
                  className="barcode-scanner"
                  shouldAutoStart
                  options={getBarcodeScannerOptions()}
                  onSuccess={onBarcodeScannerSuccess}
                  onError={onBarcodeScannerError}
                />
              )}
              <IonButton onClick={flipBarcodeScannerCamera}>Flip Camera</IonButton>
            </>
          ) : (
            <WebBarcodeScanner
              className="barcode-scanner"
              shouldAutoStart
              options={getBarcodeScannerOptions()}
              onSuccess={onBarcodeScannerSuccess}
              onError={onBarcodeScannerError}
            />
          )}
          <div className="btn-container">
            <IonButton className="generic-button skip" onClick={goToNextStepSkipped}>
              Skip
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
});

export default Scan;
