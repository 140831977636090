import { Preferences as Storage } from '@capacitor/preferences';

export default class CameraUtils {
  static CAMERA_FRONT_SIDE = 'CAMERA_FRONT_SIDE';

  static getCameraFrontSide = async () => {
    return Storage.get({ key: CameraUtils.CAMERA_FRONT_SIDE }).then(async (result: any) => {
      if (result.value) {
        return result.value === 'true';
      } else {
        return await CameraUtils.setCameraFrontSide('false');
      }
    });
  };

  static setCameraFrontSide = async (side: string) => {
    Storage.set({
      key: CameraUtils.CAMERA_FRONT_SIDE,
      value: side,
    });
    return side === 'true';
  };
}
