import React from 'react';
import AppStore from '../mobx/AppStore';
import Utils from '../utils/Utils';
import NativeStore from '../mobx/NativeStore';
import { IonButton, IonLabel } from '@ionic/react';
import '../theme/components/hiddenMenu.css';
import { Etc } from '../commons/Constants';
import NativeBarcodeScanner from './NativeBarcodeScanner';
import BarcodeScannerResults from '../models/BarcodeScannerResults';
import BarcodeScannerOptions from '../models/BarcodeScannerOptions';
import { Deploy } from 'cordova-plugin-ionic';
import { rollbarHandler } from './RollbarErrorHandler';
import BadgeUtils, { BadgePrintInterface } from '../utils/BadgeUtils';
import CameraUtils from '../utils/CameraUtils';
import { FailedCheckInCounter } from '../utils/FailedCheckInCounter';
import PrinterUtils from '../plugins/PrinterUtils';
import { Preferences as Storage } from "@capacitor/preferences";

interface Props {
  appStore: AppStore;
  close: () => void;
  appVersion: string;
  stopPropagation?: boolean;
}

type State = {
  status: string;
  isScanningBarcode: boolean;
  updatePercentage: number | undefined;
  updateAvailable: boolean;
  currentVersion?: string;
  failedCheckInNumberTotal?: number;
  paperType?: string | null;
  isFrontCamera: boolean;
};

const ERROR_HANDLER = rollbarHandler();

// only works with native
export class HiddenMenu extends React.Component<Props, State> {
  private barcodeScanner = React.createRef<NativeBarcodeScanner>();
  private isBarcodeScannerDLOnly = false;
  private isBarcodeScannerFront = true;

  state: State = {
    status: '',
    isScanningBarcode: false,
    updatePercentage: 0,
    updateAvailable: false,
    paperType: null,
    isFrontCamera: false
  };

  logout = () => {
    if (Utils.isNative()) {
      const nativeStore = this.props.appStore as NativeStore;
      nativeStore.logout();
    }
  };

  resetState = () => {
    this.setState({ status: '' });
  };

  private printBadge = async () => {
    const { locationStore } = this.props.appStore;
    const { badgeSetting } = locationStore.location!;

    this.resetState();
    this.setState({
      status: 'Printing at ' + (badgeSetting.ipAddress || 'bluetooth'),
    });

    const badgePrint: BadgePrintInterface = {
      visitorInfoFieldValues: {
        first_name: 'TheFirstName',
        last_name: 'TheLastName',
        company: 'TheCompany',
      },
      hostName: 'TheHostName',
      visitorImgUrl: null,
      companyImgUrl: locationStore.location?.logo
        ? await BadgeUtils.addProxyToURL(encodeURIComponent(locationStore.location.logo))
        : null,
      badgeText: Utils.parseMessage(locationStore.location!.badgeSetting.badgeText, [
        {
          tag: 'check_in_option_name',
          source: () => {
            return 'exampleName';
          },
        },
        {
          tag: 'visitor_id',
          source: () => {
            return 'exampleId';
          },
        },
      ]),
    };

    Utils.printBadge(badgePrint, badgeSetting)
      .then(() => {
        console.debug(Etc.LOG_PREFIX + 'Printing successfully');
        this.setState({ status: 'Printing successfully' });
      })
      .catch((err) => {
        console.error(Etc.LOG_PREFIX + JSON.stringify(err));
        this.setState({ status: 'Printing failed: ' + JSON.stringify(err) });
      });
  };

  private getConnectedPrinterInfo = async () => {
    if (Utils.isNative()) {
      if (await PrinterUtils.isPrinterConnected()) {
        this.setState({ status: `Connected device: ${await PrinterUtils.getInfoConnectedPrinters()}` });
      } else {
        this.searchConnectedPrinters();
      }
    } else {
      this.setState({ status: "Printer isn't available on your device" });
    }
  };

  private searchConnectedPrinters = async () => {
    if (Utils.isNative()) {
      this.setState({ status: 'Searching connected printers...' });
      const isBluetoothPrinter = this.props.appStore.isBluetoothPrinter ?? true;

      await PrinterUtils.searchConnectedPrinters(isBluetoothPrinter);
      while (await PrinterUtils.isSearchingPrinters()) {
        if (await PrinterUtils.isPrinterConnected()) {
          this.setState({ status: `Searching... Found: ${await PrinterUtils.getInfoConnectedPrinters()}` });
        }
        await Utils.sleep(1000);
      }
      this.setState({ status: `Searching is completed. Found: ${await PrinterUtils.getInfoConnectedPrinters()}` });
    } else {
      this.setState({ status: "Searching printers isn't availabe on your device" });
    }
  };

  reloadLocation = () => {
    const nativeStore = this.props.appStore as NativeStore;
    nativeStore.fetchLocation(true).then(() => {
      this.props.close();
    });
  };

  testBarcodeScanner = (dlOnly: boolean) => {
    this.isBarcodeScannerFront = true;
    this.isBarcodeScannerDLOnly = dlOnly;
    this.setState({ isScanningBarcode: true });
  };

  closeBarcodeScanner = () => {
    this.setState({ isScanningBarcode: false });
  };

  getBarcodeScannerOptions = (): BarcodeScannerOptions => {
    let possibleFormats: Array<string> = [];

    if (this.isBarcodeScannerDLOnly) {
      possibleFormats = ['PDF_417'];
    } else {
      possibleFormats = [
        'QR_CODE',
        'EAN_8',
        'EAN_13',
        'CODE_39',
        'CODE_93',
        'CODE_128',
        'UPC_A',
        'UPC_E',
        'CODABAR',
        'PDF_417',
        'ITF',
        'RSS14',
        'MSI',
        'DATA_MATRIX',
        'AZTEC',
        'RSS_EXPANDED',
      ];
    }

    return {
      possibleFormats: possibleFormats,
      isFrontCamera: this.isBarcodeScannerFront,
    };
  };

  onBarcodeScannerSuccess = (res: BarcodeScannerResults) => {
    this.setState({ status: JSON.stringify(res), isScanningBarcode: false });
  };

  onBarcodeScannerError = (err: any) => {
    this.setState({ status: 'Barcode Scanner Error: ' + JSON.stringify(err) });
  };

  flipBarcodeScannerCamera = () => {
    this.isBarcodeScannerFront = !this.isBarcodeScannerFront;
    this.barcodeScanner.current?.restart(this.getBarcodeScannerOptions());
  };

  _pinApp = () => {
    const nativeStore = this.props.appStore as NativeStore;
    nativeStore.pinApp();
  };

  componentDidMount() {
    Deploy.checkForUpdate().then((update) => this.setState({ updateAvailable: update.available }));
    // maybe use this for debug, not sure we want it displayed
    Deploy.getCurrentVersion().then((info) => {
      this.setState({ currentVersion: info?.buildId });
    });

    FailedCheckInCounter.shared.failedCheckInNumberTotal().then((value) => {
      this.setState({ failedCheckInNumberTotal: value });
    });

    this._setPaperTypeToState();
    this._setCameraSideToState();
  }

  private async _setPaperTypeToState() {
    this.setState({ paperType: await BadgeUtils.getPaperType() });
  }

  private async _setCameraSideToState() {
    this.setState({ isFrontCamera: await CameraUtils.getCameraFrontSide() });
  }

  private async _nextPaperType() {
    await BadgeUtils.nextPaperType();
    this._setPaperTypeToState();
  }

  private async setCameraSide() {
    const currentSide = this.state.isFrontCamera;
    await Storage.set({ key: CameraUtils.CAMERA_FRONT_SIDE, value: !currentSide ? 'true' : 'false' }).then(() => {
      this.setState({ isFrontCamera: !currentSide });
    });
  }

  // Does not work as advertised according to ionic documentation
  async performAutomaticUpdate() {
    try {
      await Deploy.sync({ updateMethod: 'background' }, (percentDone) => {
        this.setState({ updatePercentage: percentDone });
      });

      this.setState({ updateAvailable: false, updatePercentage: 0 });
    } catch (error) {
      ERROR_HANDLER.log(error as Date);
    }
  }

  render() {
    return (
      <div
        className="hidden-menu"
        onClick={(event) => {
          if (this.props.stopPropagation) {
            event.stopPropagation();
          }
        }}
      >
        <div className="hidden-container">
          <div className="hidden-status">{this.state.status !== '' && this.state.status}</div>
          {this.state.isScanningBarcode ? (
            <>
              <NativeBarcodeScanner
                ref={this.barcodeScanner}
                className="hidden-barcode-scanner-prev"
                shouldAutoStart
                options={this.getBarcodeScannerOptions()}
                onSuccess={(res) => this.onBarcodeScannerSuccess(res)}
                onError={(err) => this.onBarcodeScannerError(err)}
              />
              <IonButton onClick={() => this.flipBarcodeScannerCamera()}>Flip Camera</IonButton>
              <IonButton onClick={() => this.closeBarcodeScanner()}>Close</IonButton>
            </>
          ) : (
            <>
              <IonLabel>
                {/* App Version: {this.props.appVersion}-
                {this.state.currentVersion || ""} */}
                App Version: {this.props.appVersion}
              </IonLabel>

              <IonButton className="hidden-btn" onClick={() => this.reloadLocation()}>
                Reload Location
              </IonButton>
              {(this.props.appStore as NativeStore).isPlatformAndroid ? (
                <IonButton className="hidden-btn" onClick={() => this._pinApp()}>
                  Pin App
                </IonButton>
              ) : null}
              <IonButton
                className="hidden-btn"
                onClick={() => {
                  this._nextPaperType();
                }}
              >
                Current Paper Type: {this.state.paperType}
              </IonButton>
              <IonButton
                className="hidden-btn"
                onClick={() => {
                  this.setCameraSide();
                }}
              >
                <>Current Camera Side: {this.state.isFrontCamera ? 'Front' : 'Back'}</>
              </IonButton>
              <IonButton className="hidden-btn" onClick={() => this.printBadge()}>
                Test Print Badge
              </IonButton>
              <IonButton className="hidden-btn" onClick={() => this.testBarcodeScanner(false)}>
                Test Barcode Scanner
              </IonButton>
              <IonButton className="hidden-btn" onClick={() => this.testBarcodeScanner(true)}>
                Test Driver License Scanner
              </IonButton>
              <IonButton className="hidden-btn" onClick={() => this.logout()}>
                Logout
              </IonButton>
              <IonButton className="hidden-btn" onClick={() => this.props.close()}>
                Close
              </IonButton>
              <IonLabel>
                {`Failed Check-Ins Total: ${this.state.failedCheckInNumberTotal} / Failed Check-Ins Current Session: ${FailedCheckInCounter.shared.failedCheckInNumber}`}
              </IonLabel>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default HiddenMenu;
