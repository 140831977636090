export class Etc {
  public static get LOG_PREFIX() {
    return 'GreetlyLog ';
  }
}

export class TauriApp {
  public static get PACKAGE() {
    return 'com.glorystar.tauri';
  }
  public static get MAIN_ACTIVITY() {
    return 'com.glorystar.tauri.activity.MainActivity';
  }
  public static get SPLASH_ACTIVITY() {
    return 'com.glorystar.tauri.activity.SplashActivity';
  }
  public static get ACTION_CHECK_TEMPERATURE() {
    return 'android.intent.action.CHECK_TEMPERATURE';
  }
}

export class PathName {
  public static get NOT_FOUND() {
    return '/404-page-not-found';
  }
  public static get RETRY() {
    return '/retry';
  }
  public static get UNKNOWN_ERROR() {
    return '/unknown-error';
  }
  public static get SESSION_EXPIRED() {
    return '/session-expired';
  }
}

export class WorkflowType {
  public static get VISIT_HOST() {
    return 'visit_employee';
  }
  public static get PACKAGE_DELIVERY() {
    return 'package_delivery';
  }
  public static get FOOD_DELIVERY() {
    return 'food_delivery';
  }
  public static get CHECK_OUT() {
    return 'check_out';
  }
  public static get EMPLOYEE_CHECK_IN() {
    return 'employee_check_in';
  }
}

export class StepId {
  public static get SUCCESS() {
    return 'success';
  }
  public static get SEARCH_HOST() {
    return 'employee_search';
  }
  public static get VISITOR() {
    return 'visitor_info_fields';
  }
  public static get PACKAGE_SIGNATURE() {
    return 'package_signature';
  }
  public static get SIGNATURE() {
    return 'signature';
  }
  public static get PHOTO_CAPTURE() {
    return 'photo_capture';
  }
  public static get CHECK_OUT() {
    return 'check_in_record_search';
  }
  public static get SCAN() {
    return 'scan';
  }
  public static get EMPLOYEE_CHECK_IN() {
    return 'employee_check_in';
  }
}

export class NodeId {
  public static get START_VISIT_EMPLOYEE() {
    return 'start';
  }

  public static get IS_EMPLOYEE_WORK_HOURS() {
    return 'work_hours_condition';
  }
  public static get IS_PREREGISTRATION() {
    return 'is_preregistration';
  }
  public static get IS_USER_REGISTERED() {
    return 'is_user_preregistered';
  }
  public static get WATCHLIST() {
    return 'is_on_watchlist';
  }
  public static get CUSTOM_CONDITION() {
    return 'custom_condition';
  }
  public static get FIELD_CONDITION() {
    return 'field_condition';
  }
  public static get TEMPERATURE() {
    return 'temperature_scan_condition';
  }

  public static get END_CHECKIN_VISITOR() {
    return 'check_in_visitor';
  }
  public static get END_SUCCESS() {
    return 'end_success';
  }
  public static get CHECK_OUT() {
    return 'check_out_visitor';
  }
  public static get CHECK_IN_FAILED() {
    return 'check_in_failed';
  }
  public static get SEND_QR_CODE() {
    return 'send_qr_code';
  }

  public static get EMPLOYEE_SEARCH() {
    return 'employee_lookup';
  }
  public static get SEND_TEXT() {
    return 'send_text';
  }
  public static get CUSTOM_FORM() {
    return 'custom_form';
  }
  public static get TAKE_PHOTO() {
    return 'take_photo';
  }
  public static get PRINT_BADGE() {
    return 'print_badge';
  }
  public static get SIGN_DOCUMENT() {
    return 'sign_document';
  }
  public static get SCAN() {
    return "scan_driver's_license";
  }
  public static get VIDEO_PLAYER() {
    return 'play_video';
  }
  public static get DRIVERS_LICENSE_PHOTO() {
    return 'drivers_license_photo';
  }
  public static get PACKAGE_PHOTO() {
    return 'package_photo';
  }

  // public static get SEARCH_HOST() { return "employee_search"; }
  // public static get VISITOR() { return "visitor_info_fields"; }
  // public static get PACKAGE_SIGNATURE() { return "package_signature"; }
  // public static get SIGNATURE() { return "signature"; }
  // public static get PHOTO_CAPTURE() { return "photo_capture"; }
  // public static get CHECK_OUT() { return "check_in_record_search"; }
  // public static get SCAN() { return "scan"; }
  // public static get EMPLOYEE_CHECK_IN() { return "employee_check_in"; }
}

export class Paths {
  public static get IMAGE() {
    return './assets/img';
  }
  public static get GIF() {
    return './assets/gif';
  }
  public static get VIDEO() {
    return './assets/video';
  }
  public static get SVG() {
    return './assets/svg';
  }
}

export class VisitorInfoFieldType {
  public static get TEXT() {
    return 'text_field';
  }
  public static get EMAIL() {
    return 'email_field';
  }
  public static get PHONE() {
    return 'phone_field';
  }
  public static get NUMBER() {
    return 'number_field';
  }
  public static get CHECKBOX() {
    return 'checkbox_field';
  }
  public static get MULTISELECT() {
    return 'multiselect_field';
  }
  public static get TOGGLE() {
    return 'toggle_field';
  }
  public static get RADIO_SELECT() {
    return 'radio_select_field';
  }
  public static get SELECT() {
    return 'select_field';
  }
  public static get DATE() {
    return 'date_field';
  }
  public static get SIGNATURE() {
    return 'signature';
  }
  public static get RATING() {
    return 'rating_field';
  }
  public static get SLIDER() {
    return 'slider_field';
  }
  public static get URL() {
    return 'url_field';
  }
}

export class VisitorInfoFieldType_v2 {
  public static get TEXT() {
    return 'text_field';
  }
  public static get EMAIL() {
    return 'email_field';
  }
  public static get PHONE() {
    return 'phone_field';
  }
  public static get NUMBER() {
    return 'number_field';
  }
  public static get CHECKBOX() {
    return 'checkbox_field';
  }
  public static get MULTISELECT() {
    return 'multiselect_field';
  }
  public static get TOGGLE() {
    return 'toggle_field';
  }
  public static get RADIO_SELECT() {
    return 'radio_field';
  }
  public static get SELECT() {
    return 'dropdown_field';
  }
  public static get DATE() {
    return 'date_field';
  }
  public static get SIGNATURE() {
    return 'signature';
  }
  public static get RATING() {
    return 'rating_field';
  }
  public static get SLIDER() {
    return 'range_field';
  }
  public static get URL() {
    return 'url_field';
  }
  public static get HEADER() {
    return 'header';
  }
  public static get EXTENDED_HEADER() {
    return 'extended_header';
  }
  public static get TIME_PICKER() {
    return 'time_field';
  }

  public static get DRIVER_LICENSE() {
    return 'drivers_license_field';
  }
}

export enum ScreenType {
  Tablet = 0,
  Phone = 1,
}
//  @ASK DO we need enum for orientation type
export enum OrientationType {
  Landscape = 0,
  Portrait = 1,
}
export class BuildStage {
  public static get DEVELOPMENT() {
    return 'development';
  }
  public static get STAGING() {
    return 'staging';
  }

  public static get STAGING_WAF() {
    return 'staging_waf';
  }

  public static get PRODUCTION() {
    return 'production';
  }
  public static get DEVELOPMENT_NATIVE() {
    return 'development_native';
  }
  public static get STAGING_NATIVE() {
    return 'staging_native';
  }

  public static get STAGING_WAF_NATIVE() {
    return 'staging_waf_native';
  }

  public static get PRODUCTION_NATIVE() {
    return 'production_native';
  }
  public static get PRODUCTION_EU() {
    return "production_eu";
  }
  public static get PRODUCTION_NATIVE_EU() {
    return "production_native_eu";
  }
  public static get DEVELOPMENT_LOCAL() {
    return 'development_local';
  }
}

export class TemperatureSettings {
  public static NORMAL() {
    return 97.9;
  }
  public static MARGIN() {
    return 1.016;
  } //for scanner's error
  public static MAX() {
    return this.NORMAL() * this.MARGIN();
  }
}

export enum ThemeType {
  Old = '',
  Dark = 'dark',
  Modern = 'modern',
  Whimsical = 'whimsical'
}
