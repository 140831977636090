import { Preferences as Storage } from '@capacitor/preferences';

import { BuildStage } from './commons/Constants';

type EnvironmentData = {
  stage: string;
  baseURL: string;
  rubyServerURL: string;
  nodeServerURL: string;
  isNative: boolean;
  checkInURL: string;
  devBaseURL?: string;
  devRubyServerURL?: string;
};

class Environment {
  private _data: EnvironmentData;

  constructor(data: EnvironmentData) {
    this._data = data;
  }

  get stage() {
    return this._data.stage;
  }

  get baseURL() {
    return this._data.baseURL;
  }

  get rubyServerURL() {
    return this._data.rubyServerURL;
  }

  get nodeServerURL() {
    return this._data.nodeServerURL;
  }

  get isNative() {
    return this._data.isNative;
  }

  get checkInURL() {
    return this._data.checkInURL;
  }
}

function getEnv(): Environment {
  const env = process.env.REACT_APP_BUILD_STAGE;
  const devBaseURL = process.env.REACT_APP_DEV_BASE_URL || 'http://localhost:5002';
  const devRubyServerURL = process.env.REACT_APP_DEV_RUBY_SERVER_URL || 'http://localhost:5001';
  const devNodeServer = process.env.REACT_APP_DEV_NODE_SERVER_URL || 'http://localhost:8100';
  const devCheckInURL = process.env.REACT_APP_DEV_CHECK_IN_URL || 'http://localhost:5001/check_in';

  switch (env) {
    case 'development-local':
      return new Environment({
        stage: BuildStage.DEVELOPMENT_LOCAL,
        baseURL: devBaseURL,
        rubyServerURL: devRubyServerURL,
        nodeServerURL: devNodeServer,
        isNative: false, // only for WEB in dev mode
        checkInURL: devCheckInURL,
      });
    case 'development':
      return new Environment({
        stage: BuildStage.DEVELOPMENT,
        baseURL: 'https://greetly-visitor-dev.herokuapp.com',
        rubyServerURL: 'https://greetly-dev.herokuapp.com',
        nodeServerURL: 'https://greetly-visitor-dev.herokuapp.com',
        isNative: false,
        checkInURL: '',
      });
    case 'staging':
      return new Environment({
        stage: BuildStage.STAGING,
        baseURL: 'https://greetly-visitor-qa.herokuapp.com',
        rubyServerURL: 'https://greetly-qa.herokuapp.com',
        nodeServerURL: 'https://greetly-visitor-qa.herokuapp.com',
        isNative: false,
        checkInURL: '',
      });
    case 'staging-waf':
      return new Environment({
        stage: BuildStage.STAGING_WAF,
        baseURL: 'https://greetly-visitor-qa.herokuapp.com',
        rubyServerURL: 'https://app.qa.greetly.com',
        nodeServerURL: 'https://greetly-visitor-qa.herokuapp.com',
        isNative: false,
        checkInURL: '',
      });
    case 'production':
      return new Environment({
        stage: BuildStage.PRODUCTION,
        baseURL: 'https://visitor.greetly.com',
        rubyServerURL: 'https://app.greetly.com',
        nodeServerURL: 'https://visitor.greetly.com',
        isNative: false,
        checkInURL: '',
      });
    case 'release':
      return new Environment({
        stage: BuildStage.STAGING,
        baseURL: 'https://greetly-visitor-release-17143b27545e.herokuapp.com',
        rubyServerURL: 'https://greetly-release-17eee0200498.herokuapp.com',
        nodeServerURL: 'https://greetly-visitor-release-17143b27545e.herokuapp.com',
        isNative: false,
        checkInURL: '',
      });
    case 'development-native-local':
      return new Environment({
        stage: BuildStage.DEVELOPMENT_NATIVE,
        baseURL: devRubyServerURL,
        rubyServerURL: devRubyServerURL,
        nodeServerURL: devBaseURL,
        isNative: true,
        checkInURL: `${devRubyServerURL}/check_in`,
      });
    case 'development-native':
      return new Environment({
        stage: BuildStage.DEVELOPMENT_NATIVE,
        baseURL: 'https://greetly-dev.herokuapp.com',
        rubyServerURL: 'https://greetly-dev.herokuapp.com',
        nodeServerURL: 'https://greetly-visitor-dev.herokuapp.com',
        isNative: true,
        checkInURL: 'https://greetly-dev.herokuapp.com/check_in',
      });
    case 'staging-native':
      return new Environment({
        stage: BuildStage.STAGING_NATIVE,
        baseURL: 'https://greetly-qa.herokuapp.com',
        rubyServerURL: 'https://greetly-qa.herokuapp.com',
        nodeServerURL: 'https://greetly-visitor-qa.herokuapp.com',
        isNative: true,
        checkInURL: 'https://greetly-qa.herokuapp.com/check_in',
      });
    case 'staging-waf-native':
      return new Environment({
        stage: BuildStage.STAGING_WAF_NATIVE,
        baseURL: 'https://app.qa.greetly.com',
        rubyServerURL: 'https://app.qa.greetly.com',
        nodeServerURL: 'https://greetly-visitor-qa.herokuapp.com',
        isNative: true,
        checkInURL: 'https://app.qa.greetly.com/check_in',
      });
    case 'release-native':
      return new Environment({
        stage: BuildStage.STAGING_NATIVE,
        baseURL: 'https://greetly-release-17eee0200498.herokuapp.com',
        rubyServerURL: 'https://greetly-release-17eee0200498.herokuapp.com',
        nodeServerURL: 'https://greetly-visitor-release-17143b27545e.herokuapp.com',
        isNative: true,
        checkInURL: 'https://greetly-release-17eee0200498.herokuapp.com/check_in',
      });
    case 'production-native':
      return new Environment({
        stage: BuildStage.PRODUCTION_NATIVE,
        baseURL: 'https://app.greetly.com',
        rubyServerURL: 'https://app.greetly.com',
        nodeServerURL: 'https://visitor.greetly.com',
        isNative: true,
        checkInURL: 'https://app.greetly.com/check_in',
      });
    case 'production-eu':
      return new Environment({
        stage: BuildStage.PRODUCTION_EU,
        baseURL: 'https://eu-visitor.greetly.com',
        rubyServerURL: 'https://eu.greetly.com',
        nodeServerURL: 'https://eu-visitor.greetly.com',
        isNative: false,
        checkInURL: '',
      });
    default:
      return new Environment({
        stage: BuildStage.DEVELOPMENT_LOCAL,
        baseURL: devBaseURL,
        rubyServerURL: devRubyServerURL,
        nodeServerURL: devNodeServer,
        isNative: false, // only for WEB in dev mode
        checkInURL: devCheckInURL,
      });
  }
}

const ENV: Environment = getEnv();
export default ENV;
